/*
@html    container > a.dropdown-toggle + ul
@param   settings.container   <HTMLDivElement>
@param   settings.position    'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
*/
export default function Dropdown (settings) {
  this.container = settings.container;
  this.position = settings.position || 'top-left';

  if (this.container) {
    this.toggle = this.container.querySelector('.dropdown-toggle');
    this.list = this.container.querySelector('ul');
    this.anchors = this.list.querySelectorAll('a');
    this.list.className = `align-${this.position}`;
    this.bindHandlers();
  }
}

/*
Bind handlers
*/
Dropdown.prototype.bindHandlers = function() {
  this.toggle.addEventListener('click', (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (!this.toggle.classList.contains('active')) {
      this.toggle.classList.add('active')
      this.list.classList.add('active');
    } else {
      this.toggle.classList.remove('active');
      this.list.classList.remove('active');
    }
  });

  this.container.addEventListener('click', (e) => {
    e.stopPropagation();
  });

  this.list.addEventListener('click', (e) => {
    if (e.target.tagName === 'A') {
      this.close();
    }
  });

  document.addEventListener('click', this.close);
  document.addEventListener('touchstart', this.close);
};

/*
Close all
*/
Dropdown.prototype.close = function() {
  let toggles = document.querySelectorAll('.dropdown-toggle'),
    lists = document.querySelectorAll('.dropdown-toggle + ul'),
    i;

  toggles.forEach((toggle) => {
    toggle.classList.remove('active');
  });
  lists.forEach((list) => {
    list.classList.remove('active');
  });
};
