/*
Navigation helper
*/
export default function Navigation (container) {
  this.container = container;
  this.navExpand = document.querySelector('.nav-expand');

  if (this.container) {
    this.container.querySelectorAll('li.expandable > ul').forEach(item => {
      this.setZeroHeight(item);
    });

    this.bind();
    this.highlight();
  }
}

Navigation.prototype.bind = function () {
  document.addEventListener('click', () => {
    this.container.classList.remove('expanded');
    document.body.classList.remove('nav-expanded');
  });

  this.container.addEventListener('click', (e) => {
    e.stopPropagation();
  });

  this.navExpand.addEventListener('click', (e) => {
    this.toggleMobileNav(e);
  });

  this.container.querySelectorAll('li.expandable').forEach(item => {
    item.addEventListener('click', (e) => {
      e.preventDefault();
      this.toggleExpandable(e.currentTarget);
    });
    item.addEventListener('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', (e) => {
      if (e.target.classList.contains('expanded')) {
        this.setAutoHeight(e.target);
      }
    });
  });

  this.container.querySelectorAll('li.expandable li').forEach(item => {
    item.addEventListener('click', (e) => {
      e.stopPropagation();
    });
  });
}

Navigation.prototype.toggleMobileNav = function (e) {
  e.stopPropagation();
  this.container.classList.toggle('expanded');
  document.body.classList.toggle('nav-expanded');
}

Navigation.prototype.toggleExpandable  = function (item) {
  let list = item.querySelector('ul');

  if (list.classList.contains('expanded')) {
    let currentHeight = list.clientHeight + 'px';
    list.style.height = currentHeight;
    list.offsetHeight; // force repaint
    list.style.height = '0px';
  } else {
    list.style.height = 'auto';
    let futureHeight = list.clientHeight + 'px';
    list.style.height = '0px';
    list.offsetHeight;
    list.style.height = futureHeight;
  }

  list.classList.toggle('expanded');
}

Navigation.prototype.setAutoHeight = function (item) {
  item.style.height = 'auto';
}

Navigation.prototype.setZeroHeight = function (item) {
  item.style.height = '0px';
}

Navigation.prototype.highlight = function () {
  let location = window.location.href;
  let anchors = this.container.querySelectorAll('a');

  anchors.forEach((anchor) => {
    if (anchor.href === location) {
      anchor.classList.add('active');

      let parent = anchor.parentNode;
      while (parent) {
        if ((parent.tagName === 'LI') && (parent.classList.contains('expandable'))) {
          var list = parent.querySelector('ul');
          list.classList.add('expanded');
          this.setAutoHeight(list);
          break;
        } else if (parent.tagName === 'NAV') {
          break;
        } else {
          parent = parent.parentNode;
        }
      }
    }
  });
}
