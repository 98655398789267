/*
Filepicker interface to enhance input[type="file"]
@param  input <HTMLInputElement>
*/
export default function Filepicker(input) {
    this.input = input;
    this.existing = document.getElementById(input.id + '-existing-image');
    this.existingValue = null;

    if (this.existing) {
        this.existingValue = this.existing.value;
    }

    this.container = document.createElement('div');
    this.container.className = 'filepicker';

    this.placeholder = document.createElement('input');
    this.placeholder.type = 'text';
    this.placeholder.className = 'filepicker-placeholder';

    const existingFilename = this.input.getAttribute('data-filename');
    if (existingFilename) {
        this.placeholder.value = existingFilename;
    }

    // Create attach file button
    this.add = document.createElement('a');
    this.add.className = 'green button add-file';
    this.add.innerHTML = '<i class="material-icons">attach_file</i> <span>Attach file</span>';

    if (this.input.multiple) {
        this.add.textContent = 'Add files';
    }

    // Create delete button
    this.clear = document.createElement('a');
    this.clear.id = 'clear-file-button';
    this.clear.className = 'red button';
    this.clear.innerHTML = '<i class="material-icons">delete</i> <span>Delete</span>';
    if (this.existingValue) {
        this.clear.style.display = '';
    } else {
        this.clear.style.display = 'none';
    }

    // Create image cropper container
    this.imageCropper = document.createElement('div');
    this.imageCropper.id = 'image-container';
    this.imageCropper.style.paddingTop = '10px';
    this.imageCropper.innerHTML = '<img id="logoImage">';

    this.preview = document.getElementById(this.input.id + '-image-preview');

    this.setup();
    this.bindHandlers();
}

/*
Create DOM
*/
Filepicker.prototype.setup = function() {
    const parent = this.input.parentNode;
    const wrapper = document.createElement('div');

    wrapper.className = 'filepicker-wrapper';
    this.input.insertAdjacentHTML('beforebegin', wrapper.outerHTML);

    this.wrapper = parent.querySelector('.filepicker-wrapper');

    this.container.appendChild(this.input);
    this.container.appendChild(this.placeholder);
    this.container.appendChild(this.add);

    this.wrapper.appendChild(this.container);
    this.wrapper.appendChild(this.clear);
    this.wrapper.appendChild(this.imageCropper);
};

/*
Bind handlers
*/
Filepicker.prototype.bindHandlers = function() {
    this.input.addEventListener('change', e => {
        const files = e.target.files;
        const fileNames = [];

        if (files || this.existingValue) {
            this.clear.style.display = '';
        }

        if (files) {
            for (let i = 0; i < files.length; i++) {
                fileNames.push(files[i].name);
            }
        } else if (!this.existingValue) {
            this.clear.style.display = 'none';
        }
        this.placeholder.value = fileNames.join(', ');
    });

    this.clear.addEventListener('click', () => {
        this.clear.style.display = 'none';
        this.placeholder.value = '';
        if (this.existing) {
            this.existing.value = '';
        }
        if (this.preview) {
            this.preview.src = '';
        }
        this.input.type = 'file';

        // Remove image in the image cropper
        document.getElementById('image-container').remove();
        // Create image cropper element again
        this.imageCropper = document.createElement('div');
        this.imageCropper.id = 'image-container';
        this.imageCropper.style.paddingTop = '10px';
        this.imageCropper.innerHTML = '<img id="logoImage">';
        this.wrapper.appendChild(this.imageCropper);
    });
};
