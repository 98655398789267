import Dropdown from "components/dropdown"
import Navigation from "components/navigation"
import Filepicker from "components/filepicker"
import Select from "components/select"
import autosize from "autosize"
import Waves from "node-waves"
import "node-waves/dist/waves.min.css"
import Ps from "perfect-scrollbar"

//
// Nodelist forEach polyfill
//
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, argument) {
        argument = argument || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(argument, this[i], i, this);
        }
    };
}

//
// Newsletter
//
var newsletterSignup = document.getElementById('newsletter-signup');
var newsletterSignupButton = document.querySelector('.newsletter-signup-button');

if (newsletterSignup && newsletterSignupButton) {
  var closeNewsletterModal = newsletterSignup.querySelector('.close-newsletter-modal');
  var modalInner = newsletterSignup.querySelector('.inner');

  newsletterSignupButton.addEventListener('click', (e) => {
    e.preventDefault();
    newsletterSignup.classList.add('visible');
  });

  newsletterSignup.addEventListener('click', (e) => {
    e.stopPropagation();
    newsletterSignup.classList.remove('visible');
  });

  modalInner.addEventListener('click', (e) => {
    e.stopPropagation();
  });

  closeNewsletterModal.addEventListener('click', (e) => {
    e.preventDefault();
    newsletterSignup.classList.remove('visible');
  });
}

//
// Dropdowns
//
let dropdowns = document.querySelectorAll('.dropdown');
Array.prototype.slice.call(dropdowns).forEach(dropdownContainer => {
  new Dropdown({
    container: dropdownContainer,
    position: 'top-right'
  });
});

//
// Navigation
//
let nav = document.querySelector('#sidebar .expandable-nav');
let scrollContainer = document.querySelector('#scroll-container');
if (nav) {
  new Navigation(nav);
  Ps.initialize(scrollContainer);
}

//
// Success notifications
//
if (window.location.href.indexOf('success=true') > -1) {
  let notification = document.getElementById('form-success-notification');
  if (notification) {
    notification.style.display = 'block';
  }
}

//
// Filepickers
//
let fileinputs = document.querySelectorAll('input[type="file"]');
Array.prototype.slice.call(fileinputs).forEach(fileInput => {
    new Filepicker(fileInput);
});

//
// Autosize
//
autosize(document.querySelectorAll('textarea'));

//
// Select multi
//
let selectMulti = document.querySelectorAll('select[multiple]');
Array.prototype.slice.call(selectMulti).forEach(select => {
  new Select({
    element: select
  });
});

//
// Waves
//
Waves.init();
